<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" fill="none">
        <path fill="#fff"
              d="M46.176 41.826a1.719 1.719 0 1 0-2.432 2.43 1.719 1.719 0 0 0 2.432-2.43ZM68.05 19.95a8.59 8.59 0 0 0-12.153 0c-3.35 3.35-3.35 8.801 0 12.153 3.351 3.35 8.803 3.35 12.154 0a8.59 8.59 0 0 0 0-12.154Zm-2.43 9.722a5.163 5.163 0 0 1-7.292 0 5.163 5.163 0 0 1 0-7.292 5.154 5.154 0 0 1 7.292 0 5.154 5.154 0 0 1 0 7.292Z"
        />
        <path fill="#fff"
              d="M87.496.503A1.713 1.713 0 0 0 86.22.001c-.388.014-9.641.386-20.64 4.274-8.814 3.112-16.16 7.567-21.836 13.242a65.376 65.376 0 0 0-3.693 4.022c-5.611-3.303-10.108-2.268-12.95-.746-6.546 3.502-10.597 13.013-10.597 19.816a1.72 1.72 0 0 0 2.935 1.215c3.452-3.451 7.709-3.235 9.1-3.053l.602.602c-1.185 2.819-2.14 5.622-2.839 8.348a4.865 4.865 0 0 0 .208 2.986c-1.655.762-3.251 1.882-4.641 3.272-4.424 4.424-5.323 15.207-5.359 15.664a1.719 1.719 0 0 0 1.849 1.848c.456-.036 11.24-.934 15.663-5.358 1.39-1.39 2.51-2.986 3.272-4.64a4.855 4.855 0 0 0 2.987.206c2.725-.7 5.528-1.654 8.347-2.838l.602.602c.182 1.39.398 5.648-3.053 9.1a1.719 1.719 0 0 0 1.215 2.933c6.803 0 16.314-4.05 19.816-10.596 1.522-2.842 2.557-7.339-.746-12.95a65.408 65.408 0 0 0 4.022-3.694c5.675-5.675 10.13-13.021 13.242-21.834 3.888-11 4.26-20.253 4.274-20.642a1.72 1.72 0 0 0-.503-1.277Zm-67.15 36.41c1.068-5.168 4.169-10.838 8.376-13.09 2.812-1.504 5.885-1.357 9.153.432a69.498 69.498 0 0 0-7.221 11.771c-.026-.016-.335-.405-.91-.534-.236-.054-4.784-1.032-9.398 1.42ZM31.59 63.701c-2.364 2.363-7.907 3.61-11.411 4.119.509-3.505 1.755-9.048 4.118-11.411 1.324-1.324 2.858-2.327 4.4-2.892l5.785 5.784c-.566 1.542-1.569 3.077-2.892 4.4Zm7.835-5.333c-.497.127-1.073-.06-1.501-.487l-3.367-3.366-4.439-4.44c-.428-.429-.615-1.005-.488-1.501a55.816 55.816 0 0 1 2.146-6.565L45.99 56.224a55.776 55.776 0 0 1-6.564 2.145Zm24.751.909c-2.252 4.207-7.922 7.309-13.089 8.376 1.648-3.1 2.011-7.046 1.41-9.44-.14-.555-.503-.835-.523-.867a69.518 69.518 0 0 0 11.77-7.222c1.79 3.268 1.937 6.342.432 9.153Zm3.875-17.452a62.394 62.394 0 0 1-4.957 4.447 66.036 66.036 0 0 1-13.701 8.493l-16.16-16.16a66.025 66.025 0 0 1 8.493-13.7 62.371 62.371 0 0 1 4.448-4.958c5.092-5.092 11.663-9.144 19.537-12.061l14.402 14.401c-2.917 7.874-6.97 14.446-12.062 19.538ZM81.35 18.663 69.337 6.65c6.545-2.033 12.24-2.794 15.081-3.068-.274 2.843-1.035 8.537-3.068 15.081Z"
        />
        <path fill="#FF0059"
              d="M41.314 70.993a1.719 1.719 0 0 0-2.431 0l-4.86 4.862a1.719 1.719 0 0 0 2.43 2.43l4.861-4.862c.671-.671.67-1.76 0-2.43ZM17.008 46.686a1.719 1.719 0 0 0-2.43 0l-4.863 4.86a1.719 1.719 0 1 0 2.43 2.432l4.862-4.86a1.719 1.719 0 0 0 0-2.432ZM29.16 73.425a1.719 1.719 0 0 0-2.43 0l-11.643 11.64a1.718 1.718 0 1 0 2.43 2.431l11.643-11.64a1.718 1.718 0 0 0 0-2.431ZM15.41 73.425a1.719 1.719 0 0 0-2.43 0L1.337 85.065a1.719 1.719 0 1 0 2.43 2.431l11.644-11.64a1.718 1.718 0 0 0 0-2.431ZM14.575 58.84a1.719 1.719 0 0 0-2.43 0L.504 70.481a1.719 1.719 0 0 0 2.43 2.43L14.576 61.27a1.719 1.719 0 0 0 0-2.43Z"
        />
        <path fill="#fff"
              d="M53.467 34.533a1.719 1.719 0 0 0-2.43 0l-2.43 2.43a1.719 1.719 0 0 0 2.43 2.431l2.43-2.43a1.719 1.719 0 0 0 0-2.431Z"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
